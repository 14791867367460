.header {
    /* margin-bottom: 25px; */
    color: #020078;
    padding: 20px 20px 5px;
    background: #fff;
    max-width: calc(1260px - 40px);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: sticky;
    top: 0;
    box-shadow: 0 0 10px 2px rgba(195, 195, 195, 0.753);
    box-shadow: 0px 4px 14px 0px rgb(195 195 195 / 75%);
    z-index: 501;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.header-email-and-google-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* flex-basis: 50%; */
    max-width: 600px;
    margin-left: auto;
}

.mena_header_logo_box {
    flex-basis: 100%;
}

.mena_header_logo {
    height: 100%;
    width: 100%;
    max-width: 440px;
    /* max-height: 44px; */
}

.header-date {
    /* margin-top: 2px; */
    font-size: 1.15rem;
    /* flex: 1; */
    min-width: 150px;
}

.email-auth {
    float: right;
    display: flex;
    justify-content: right;
    margin-top: 10px;
    margin-left: auto;
    min-width: 50%;
}

.email-connect {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
    margin-top: 1px;
}

.email-auth>button {
    /* margin-top: 20px; */
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
    /* outline: none;
    border: none; */
}

.email-auth>div>span {
    white-space: unset !important;
}

.header-categories {
    margin-top: 12px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-basis: 100%;
    /* border-bottom: 1px solid black; */
    /* padding: 0px 30px 6px 30px; */
}

.header-statics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 6px auto;
    flex-basis: 100%;
}

.header-category {
    margin: 2px 6px;
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.95rem;
}

.header-static {
    margin: 2px 6px;
    color: #323232;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.95rem;
}

.header-category:hover,
.header-static:hover {
    text-decoration: underline;
}

.header-category::before,
.header-static::before {
    content: "|";
    margin-right: 10px;
    display: inline-block;
    text-decoration: none !important;
}

.header-categories :first-child::before,
.header-statics :first-child::before {
    content: "";
    margin-right: 0;
}

.header-sticky {
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    width: calc(100% - 40px);
    padding: 20px;
}

.google_transalte_container {
    /* float: right;
    position: absolute; */
    /* right: 20px;
    top: 80px; */
    display: inline-flex;
    gap: 5px;
    opacity: 0;
    transition: all 620ms linear;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
}

.show_google_transalte {
    opacity: 1;
}