/* .table-container {
    width: 1080px;
    margin: 0 auto;
} */


/* .container {
    max-width: 1200px;
} */

.ql-editor {
    /* height: 500px !important; */
    max-height: 500px !important;
    overflow-y: auto;
    /* overflow-x: scroll; */
    /* padding: 15px 20px; */
    /* border: 1px solid #ccc; */
    /* border-radius: 12px; */
}

.ql-card-figure {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    color: transparent;
}

.ql-card-figure>figcaption {
    max-width: -webkit-fill-available !important;
    margin: 0 auto;
    font-size: 20px;
}

.ql-card-figure>img {
    max-width: -webkit-fill-available !important;
    /* max-height: 480px !important; */
}

.ql-editor>p {
    font-size: 24px;
}

.add-post-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 20px;
}