@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap');
body {
    background-color: #f1f1f1;
    padding: 20px 10px;
    /* margin: 0px auto; */
    /* font-family: 'Merriweather', serif !important; */
}

.container {
    max-width: 1260px;
    margin: 0 auto;
    /* padding: 20px 30px; */
    /* color: #020078; */
    color: #000000;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 2px rgba(195, 195, 195, 0.753);
    width: calc(100% - 20px);
}

.content-container {
    font-family: 'Merriweather', serif !important;
    padding: 20px 30px 20px 30px;
}

.content {
    /* max-width: 720px;
    margin: 0 auto; */
    /* line-height: 1.4; */
}

.content>p,
.content>figure>figcaption,
.content {
    font-size: 1.4rem;
    /* margin-block-start: 0.25em;
    margin-block-end: 0.25em; */
}

.content>*>img {
    max-width: -webkit-fill-available;
}

.content>figure {
    text-align: center;
}

.content>* {
    overflow: auto;
}

.text-logo {
    font-size: 2.6rem;
    /* margin-bottom: 25px; */
    font-weight: bolder;
    font-family: sans-serif;
    cursor: pointer;
}

.text-logo>a {
    color: inherit;
    text-decoration: inherit;
}

.sub-header {
    font-size: 2rem;
    margin-bottom: 10px;
}

.red {
    color: red;
}

.link {
    color: #605dff;
}

.bold {
    font-weight: bold;
}

figcaption {
    color: #02007880;
}

.mails-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
}

.articles-container {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
    /* max-width: 960px;
    margin: 0 auto; */
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    /* grid-template-rows: 1fr; */
    gap: 20px 40px;
}

.articles-container-search {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.article-card-search {
    flex-basis: 100%;
}

@media (max-width: 600px) {
    .articles-container {
        grid-template-columns: inherit;
    }
    .header-date {
        font-size: 1rem !important;
    }
    .google_transalte_container {
        margin: 8px auto;
    }
}

.articles-container :first-child {
    grid-column: 1 / -1;
}

.article-mini {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0 0 3px 1px #e1e1e1;
    transition: all 320ms ease-in-out;
}

.article-mini:hover,
.article-mini:focus {
    box-shadow: 0 0 3px 1px #999999;
    transform: scale(1.02);
}

.article-mini:active {
    box-shadow: 0 0 3px 1px #999999;
    transform: scale(1);
}

.article-mini>a {
    color: black;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
}

.article-title {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.article-description {
    /* font-style: italic; */
    font-size: 1rem;
    /* margin-bottom: 10px; */
}

.error-container {
    padding: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.3em;
}

.payment-option {
    font-size: 1.1rem;
    /* font-weight: bold; */
    line-height: 1.3em;
}

.not-underline {
    text-decoration: none;
}

.text-pay {
    /* float: right; */
    color: #000000;
    /* padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fafafa */
    text-align: center;
}

.text-pay>img {
    display: inline-block;
    height: 17px;
    top: 3px;
    position: relative;
    left: 10px;
}

.admin-user-options-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 20px;
    margin: 10px auto;
    padding: 10px;
}

.admin-emails-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 20px;
    margin: 10px auto;
    padding: 10px;
}

.admin-dashboard-container {
    display: flex;
    justify-content: space-evenly;
}

.admin-email-photo {
    max-height: 300px;
    cursor: not-allowed;
}

.gradient-hide-container {
    position: relative;
    z-index: 501;
}

.gradient-hide {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 1));
    /* background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); */
    position: absolute;
    width: 100%;
    /* height: 450px;
    top: -450px; */
}

.article-singin-container {
    max-width: 540px;
    margin: 10px auto;
    text-align: center;
    background: #02007817;
    padding: 10px 20px 20px;
    border-radius: 10px;
}

.article-container {
    max-width: 940px;
    margin: 10px auto;
}

.scroll-top-button {
    /* position: fixed;
    width: 100%;
    left: 50%;
    bottom: 40px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: green; */
}

.ql-editor {
    font-family: 'Merriweather', serif;
}

.ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}

.ql-font-serif {
    font-family: sans-serif !important;
}

.ql-align-justify {
    text-align: justify;
}

.ql-align-center {
    text-align: center;
}

.ql-align-left {
    text-align: left;
}

.ql-align-right {
    text-align: right;
}

.AutoPlaySwipeableViews:hover .MobileStepper,
.MobileStepper:hover {
    opacity: 1;
}